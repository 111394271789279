<script setup lang="ts">
import { subscriptionLabels } from '~/stores/useUserStore';

const { user } = useUserStore();

const subscriptionLabel = computed(() => {
  return subscriptionLabels[user.value?.subscription.type ?? 'trial'];
});

</script>

<template>
  <Tag
    severity="secondary"
    :value="subscriptionLabel"
    class="uppercase text-nowrap"
    :pt="{
      value: {
        class: '!text-nowrap'
      }
    }"
    :class="['user-subscription-pill', user?.subscription?.type]"
  />
</template>

<style lang="scss">
.user-subscription-pill {
  &.trial {
    background-color: var(--palette-green-20) !important;
    color: var(--palette-neutral-90) !important;
  }

  &.pro {
    background-color: var(--palette-neutral-80) !important;
    color: var(--palette-yellow-50) !important;
  }

  &.basic {
    background-color: var(--palette-blue-10) !important;
    color: var(--palette-blue-60) !important;
  }
}
</style>